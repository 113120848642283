function CookiesHelper(cookieName) {
    var cookiePolicyCookieName = cookieName;
    if (getCookie(cookiePolicyCookieName) === 'true') {

    } else {
        if (document.getElementsByClassName('cookiePolicy').length === 0) {
            var coo = document.createElement('div');
            coo.id = "accept-cookies";
            coo.classList.add("cookiePolicy");
            coo.innerHTML = '<div class="cookiePolicyBanner"><h4>INFORMATIVA</h4>' +
                '<p>' +
                'Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalit&agrave; illustrate nella cookie policy.' +
                'Se vuoi saperne di pi&ugrave; o negare il consenso a tutti o ad alcuni cookie, consulta la ' +
                '<a href="cookiespolicy.html" title="link a pdf con la cookies policy" >cookie policy</a>.<br/>' +
                'Chiudendo questo banner, scorrendo questa pagina, cliccando su un link o proseguendo la navigazione in altra maniera, acconsenti all&#x27;uso dei cookie</p>' +
                '<p><a class="cookiebtn" href="#">Ho capito, desidero proseguire</a></p>' +
                '</div>';

            document.body.appendChild(coo);
            setTimeout(detectInteraction, 1000);


        }
    }

    function detectInteraction() {
        document.getElementById('accept-cookies').addEventListener("click", acceptCookies);
        document.getElementsByClassName('cookiebtn')[0].addEventListener("click", acceptCookies);
        window.addEventListener("scroll", acceptCookies);

    }

    function addCookiers() {

    }

    function removeAllJSCookies(e) {
        e.preventDefault();
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            eraseCookie(cookies[i].split("=")[0]);
        }
        location.reload();
    }

    function acceptCookies(e) {
        e.preventDefault();
        document.getElementById('accept-cookies').removeEventListener("click", acceptCookies);
        document.getElementsByClassName('cookiebtn')[0].removeEventListener("click", acceptCookies);
        window.removeEventListener("scroll", acceptCookies);
        document.getElementById('accept-cookies').remove();

        setCookie(cookiePolicyCookieName, true, 365, '/');
        // addCookiers();

    }

    function eraseCookie(name) {

        setCookie(name, "", -1, '/');
    }

    function getCookie(w) {
        cName = "";
        pCOOKIES = [];
        pCOOKIES = document.cookie.split('; ');
        for (bb = 0; bb < pCOOKIES.length; bb++) {
            NmeVal = [];
            NmeVal = pCOOKIES[bb].split('=');
            if (NmeVal[0] == w) {
                cName = unescape(NmeVal[1]);
            }
        }
        return cName;
    }

    function printCookies(w) {
        cStr = "";
        pCOOKIES = [];
        pCOOKIES = document.cookie.split('; ');
        for (bb = 0; bb < pCOOKIES.length; bb++) {
            NmeVal = [];
            NmeVal = pCOOKIES[bb].split('=');
            if (NmeVal[0]) {
                cStr += NmeVal[0] + '=' + unescape(NmeVal[1]) + '; ';
            }
        }
        return cStr;
    }

    function setCookie(name, value, expires, path, domain, secure) {
        cookieStr = name + "=" + escape(value) + "; ";

        if (expires) {
            expires = setExpiration(expires);
            cookieStr += "expires=" + expires + "; ";
        }
        if (path) {
            cookieStr += "path=" + path + "; ";
        }
        if (domain) {
            cookieStr += "domain=" + domain + "; ";
        }
        if (secure) {
            cookieStr += "secure; ";
        }

        document.cookie = cookieStr;
    }

    function setExpiration(cookieLife) {
        var today = new Date();
        var expr = new Date(today.getTime() + cookieLife * 24 * 60 * 60 * 1000);
        return expr.toGMTString();
    }
}
new CookiesHelper("brianzaDentalCoookieAcepted");